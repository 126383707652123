import { createRouter, createWebHistory } from "vue-router";
import pathLoader from "../plugins/loader";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: pathLoader("login", "index"),
  },
  {
    path: "/reset",
    name: "resetPassword",
    component: pathLoader("resetPassword", "index"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: pathLoader("registration", "index"),
  },
  {
    path: "/autologin/:token",
    name: "Autologin",
    component: pathLoader("autoLogin", "index"),
  },
  {
    path: "/",
    name: "Home",
    component: pathLoader("home", "index"),
    redirect: "/webinar",
    children: [
      {
        path: "/formation",
        name: "Formation",
        component: pathLoader("formation", "index"),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/webinar",
        name: "Webinar",
        component: pathLoader("webinar", "index"),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/details-formation",
        name: "Details-Formation",
        component: pathLoader("detailsFormation", "index"),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/details-webinar/:id",
        name: "Details-Webinar",
        component: pathLoader("detailsWebinar", "index"),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/learning-history",
        name: "Learning-History",
        component: pathLoader("learningHistory", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/wish-list",
        name: "Wish-List",
        component: pathLoader("wishList", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/formation-module",
        name: "Formation-Module",
        component: pathLoader("formationModule", "index"),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/webinar-module",
        name: "Webinar-Module",
        component: pathLoader("webinarModule", "index"),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/quiz/:id",
        name: "Quiz",
        component: pathLoader("quiz", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/clinical-case",
        name: "ClinicalCase",
        component: pathLoader("clinicalCase", "index"),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/details-clinical-case/:id",
        name: "Details-Clinical-Case",
        component: pathLoader("detailsClinicalCase", "index"),
        meta: {
          requireAuth: false,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // const uid = user.uid
        next();
      } else {
        next({
          path: "/",
        });
      }
    });
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
